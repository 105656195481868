import React, { Component, lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { connect } from 'react-redux';

import Shimmer from './components/shimmer/shimmer.component';
import './App.css';

const HelpPage = lazy(() => import('./pages/helppage/helppage.component'))

class App extends Component {
  constructor(props) {
    super(props)
    this.props.reset()
  }
  render() {
    return (
      <Suspense fallback={<Shimmer />}>
        <Routes>
          <Route path="/ttbhelp/:id" element={<HelpPage />} />
        </Routes>
      </Suspense>
    );
  };

}
const mapDispatchToProps = dispatch => ({
  reset: () => dispatch({ type: 'RESET' }),
})

export default connect(null, mapDispatchToProps)(App);
