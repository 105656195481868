import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import trainReducer from './train/train.reducer';

const persistConfig = {
    key: 'TTBRY',
    storage,
    whitelist: ['train'],
    blacklist: []
}

const appReducer = combineReducers({
    train:trainReducer,

});

const rootReducer = (state, action) => {
    if (action.type === "RESET") {
        storage.removeItem('persist:TTBRY');
        localStorage.clear();
        state = undefined;
    }
    return appReducer(state, action);
}

export default persistReducer(persistConfig,rootReducer);