import trainActionTypes from "./train.types";

export const detailsFetchStart = () => ({
    type: trainActionTypes.DETAILS_FETCH_START
})
export const detailsFetchSuccess = (data) => ({
    type: trainActionTypes.DETAILS_FETCH_SUCCESS,
    payload: data
})
export const detailsFetchFail = (data) => ({
    type: trainActionTypes.DETAILS_FETCH_FAIL,
    payload: data
})
export const passengerfetchStart = () => ({
    type: trainActionTypes.PASSENGER_DETAILS_FETCH_START
})
export const setrailyatriID = (data) => ({
    type: trainActionTypes.SET_RAILYATRI_ID,
    payload: data
})
export const setUrlData = (data) => ({
    type: trainActionTypes.SET_URL_DATA,
    payload: data
})