import { createSelector } from "reselect";

const selectTrain = state => state.train

export const selectDetails = createSelector(
    [selectTrain],
    (train) => train.detailsData
)

export const selectRailyatriId = createSelector(
    [selectTrain],
    (train) => train.railyatriId
)

export const selectUrlData = createSelector(
    [selectTrain],
    (train) => train.urlData
)