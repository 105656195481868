import { persistStore } from 'redux-persist';
import { configureStore } from "@reduxjs/toolkit";
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './rootReducer';
import rootSaga from './root-saga';

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

if (process.env.NODE_ENV !== 'development') {
    middleware.push(logger);
};
export const store = configureStore({
    reducer: rootReducer,
    middleware: [sagaMiddleware,logger],
});

sagaMiddleware.run(rootSaga);
export const persistor = persistStore(store);