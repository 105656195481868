import trainActionTypes from "./train.types";

const INITIAL_STATE = {
    railyatriId: undefined,
    detailsData: [],
    isFetchingDetailsData: false,
    detailsFetchErrorMessage: undefined,
    urlData: undefined,
}

const trainReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case trainActionTypes.DETAILS_FETCH_START:
            return {
                ...state,
                isFetchingDetailsData: true
            }
        case trainActionTypes.DETAILS_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingDetailsData: false,
                detailsData: action.payload
            }
        case trainActionTypes.DETAILS_FETCH_FAIL:
            return {
                ...state,
                isFetchingDetailsData: false,
                detailsFetchErrorMessage: action.payload
            }
        case trainActionTypes.SET_RAILYATRI_ID:
            return {
                ...state,
                railyatriId: action.payload
            }
        case trainActionTypes.SET_URL_DATA:
            return {
                ...state,
                urlData: action.payload
            }
        default:
            return state;
    }
}

export default trainReducer;