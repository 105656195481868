import { call, all, takeLatest, put, select } from 'redux-saga/effects';

import { selectRailyatriId } from './train.selector';
import {
    detailsFetchFail,
    detailsFetchSuccess,
} from './train.action';
import { pnrStatus } from './train.utils'
import trainActionTypes from './train.types';

export function* onDetailsFetch() {
    try {
        const railyatriId = yield select(selectRailyatriId);
        const url = "https://assistedbooking-stage.railyatri.in/TtbSupportApi/Details/id/" + railyatriId;
        const res = yield call(fetch, url);
        const details = yield res.json();
        if (details.success === 200 && details.status === true) {
            yield put(detailsFetchSuccess(details.result));
        } else {
            yield put(detailsFetchSuccess([]));
            yield put(detailsFetchFail(details));
        }
    } catch (error) {
        yield put(detailsFetchFail(error));
    }
}

export function* DetailsFetchStart() {
    yield takeLatest(trainActionTypes.DETAILS_FETCH_START, onDetailsFetch)
}

export function* trainSaga() {
    yield all([
        call(DetailsFetchStart),
    ])
}